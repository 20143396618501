/* istanbul ignore file */

import {
  ErrorPage,
  getAppLocation,
  getCookie,
  getOTAP,
  getPathname,
  HttpErrorPage,
  NotificatiesProvider,
  PageLoading,
  SettingsContext,
  useRequestInit,
  withErrorBoundary
} from "adviesbox-shared";
import { RouteParams } from "adviesbox-shared/utils/types";
import React, { ReactElement, useContext, useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import ComponentsOverview from "../ComponentsOverview";
import OpenDossierLogProvider from "../open-dossier-log/open-dossier-log-provider";
import UserDetailsContext from "../shared/user-details/user-details-context";
import ZoekschermAjax from "../zoekscherm/zoekscherm-ajax";
import AuthenticatedDocumentBeheerAppWithDrawer from "./AuthenticatedDocumentBeheerAppWithDrawer";

const AuthenticatedDocumentBeheerApp = (): ReactElement => {
  const settingsContext = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "DOC", "POR", settingsContext.baseUrls);

  const { setUserDetails, userDetails } = useContext(UserDetailsContext);
  const { user, settings, requestInit } = useRequestInit();
  const medewerkerUrl = user && user.profile ? `${settings.licentiesOrigin}/Medewerkers/current` : null;
  const medewerker = useAbortableFetch(medewerkerUrl, requestInit) as any;
  const rechtenUrl =
    medewerker.data && typeof medewerker.data !== "string"
      ? `${settings.licentiesOrigin}/Medewerkers/${medewerker.data.medewerkerId}/Rechten`
      : null;
  const authorization = useAbortableFetch(rechtenUrl, requestInit) as any;
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);
  const vestigingId = typeof vestigingIdCookie === "string" ? vestigingIdCookie : "";

  useEffect(() => {
    if (
      !medewerker ||
      !medewerker.data ||
      typeof medewerker.data === "string" ||
      !medewerker.data.isValid ||
      !medewerker.data.medewerker ||
      !medewerker.data.medewerkerId ||
      !medewerker.data.organisatieId ||
      medewerker.data.medewerkerId === userDetails.medewerkerId
    )
      return;

    const newUserDetails = {
      ...medewerker.data.medewerker,
      medewerkerId: medewerker.data.medewerkerId,
      organisatieId: medewerker.data.organisatieId
    };

    setUserDetails(newUserDetails);
  }, [setUserDetails, medewerker, userDetails.medewerkerId]);

  if (!user || !user.profile) {
    return <ErrorPage error={Error("Geen rechten toegekend voor deze gebruiker")} data={null} />;
  }

  if (medewerker.error) {
    return <ErrorPage error={medewerker.error} data={medewerker.data} />;
  }

  if (!medewerker.data || medewerker.loading) {
    return <PageLoading />;
  }

  if (typeof medewerker.data === "string" || !medewerker.data.isValid) {
    return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
  }

  if (authorization.error) {
    return <ErrorPage error={authorization.error} data={authorization.data} />;
  }

  if (authorization.loading || !authorization.data) {
    return <PageLoading />;
  }

  if (typeof authorization.data === "string" || !authorization.data.isValid) {
    return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
  }

  if (authorization.data.rechten && !authorization.data.rechten.some((recht: string) => recht === "DOC")) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  return (
    <div data-testid="authenticated-app" className="adviesbox-advies">
      <NotificatiesProvider reloadTimeInSeconds={60} medewerkerId={medewerker.data.medewerkerId} vestigingId={vestigingId} sourceApp={"DOC"}>
        <OpenDossierLogProvider>
          <Switch>
            <Route path="/components" component={withErrorBoundary(ComponentsOverview)} />
            <Route path="/users/:id" component={withErrorBoundary(ComponentsOverview)} />
            <Route path="/vestiging/:vestiging/zoeken" component={withErrorBoundary(ZoekschermAjax)} />
            <Route
              path="/vestiging/:vestiging/adviesdossier/:adviesdossier/documentbeheer"
              component={AuthenticatedDocumentBeheerAppWithDrawer}
            />
            <Redirect from="/vestiging/:vestiging" to="/vestiging/:vestiging/zoeken" />
            <Route
              exact
              path={"/vestiging/:vestiging"}
              render={
              /* istanbul ignore next */ ({ history }: RouteComponentProps<RouteParams>) => {
                  const base = getPathname().charAt(getPathname().length - 1);
                  if (base === "/") {
                    history.push(`${getPathname()}zoeken`);
                    return <></>;
                  }
                  history.push(`${getPathname()}/zoeken`);
                  return <></>;
                }
              }
            />
            <Route
              exact
              path={"/"}
              render={
              /* istanbul ignore next */ ({ history }: RouteComponentProps) => {
                  if (!vestigingIdCookie) {
                    window.location.assign(linkToPortal);
                    return <></>;
                  }

                  history.push(`/vestiging/${vestigingIdCookie}/zoeken`);
                  return <></>;
                }
              }
            />
            <Route>
              <HttpErrorPage status={404} returnUrl={linkToPortal} />
            </Route>
          </Switch>
        </OpenDossierLogProvider>
      </NotificatiesProvider>
    </div>
  );
};

AuthenticatedDocumentBeheerApp.displayName = "AuthenticatedDocumentBeheerApp";
export default AuthenticatedDocumentBeheerApp;
