import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { AdviseurMenu, AuthContext, DossierMenu, ErrorPage, InvalidAdviesboxResultErrorPage, MenuSeperator, NavigationBar, NotificatiesMenu, SettingsType, SupportMenu, SupportRemoteAssistanceModal, SupportTicketModalAjax, useRequestInit } from "adviesbox-shared";
import classes from './TopNavbar.module.scss';
import { useAdviesboxData } from "../../hooks/use-adviesbox-data";
import { AppDataContext } from "../../../navigation/appdata-context";
import { RouteParams } from "adviesbox-shared/utils/types";
import { mapDossierDl2Ui, mapDossierUi2Dl } from "../dossier/schema-and-mappers";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { OrganisatiesOutput, VestigingenOutput } from "../../../.generated/licenties/licentiestypes";
import UserDetailsContext from "../../user-details/user-details-context";
import { getAanvragerLeeftijd, getAanvragerNaam } from "../../utils/menu-info";

const TopNavigation = (): ReactElement => {
  const { menuInfo, reloadCount, activePage } = useContext(AppDataContext);
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { params, settings, requestInit } = useRequestInit();
  const { vestiging: vestigingId } = useParams<{ vestiging: string }>();

  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);
  const [aanvrager1, setAanvrager1] = useState<{ name: string, age: number }>({ name: "Aanvrager", age: 0 });
  const [aanvrager2, setAanvrager2] = useState<{ name: string, age: number }>({ name: "Medeaanvrager", age: 0 });

  const vestiging = useAbortableFetch<VestigingenOutput>(
    `${settings.licentiesOrigin}/Vestigingen/${vestigingId}`,
    requestInit
  );

  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Dossierbalk#${reloadCount}`,
    [reloadCount]
  );
  const { error } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapDossierDl2Ui,
    mapDossierUi2Dl,
    () => null
  );

  useEffect(() => {
    if (menuInfo?.aanvrager1 && menuInfo?.aanvrager2) {
      setAanvrager1({ name: getAanvragerNaam(menuInfo.aanvrager1), age: getAanvragerLeeftijd(menuInfo.aanvrager1.geboortedatum) });
      setAanvrager2({ name: getAanvragerNaam(menuInfo.aanvrager2), age: getAanvragerLeeftijd(menuInfo.aanvrager2.geboortedatum) });
    }
  }, [menuInfo])

  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);

  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  const vestigingNaam =
    vestiging.data && typeof vestiging.data !== "string" && vestiging.data.isValid && params.vestiging
      ? vestiging.data.vestigingen?.[params.vestiging].naam
      : "";
  const organisatieNaam =
    organisatie.data && typeof organisatie.data !== "string" && organisatieId
      ? organisatie.data.organisaties?.[organisatieId].naam
      : "";

  if (error) {
    return (
      <>
        <div role="dialog" aria-modal="true" className={classes.fade}></div>
        <ErrorPage error={error} />
      </>
    );
  }

  return (
    <nav className="fixed-top">
      <NavigationBar title={activePage}>
        <DossierMenu aanvrager1={aanvrager1} aanvrager2={aanvrager2} />
        <MenuSeperator />
        <SupportMenu
          showMeldingMaken={() => setShowMeldingMaken(true)}
          showRemoteAssistance={() => setShowRemoteAssistance(true)}
          classes={{
            iconblack: classes.iconblack,
            iconpadding: classes.iconpadding
          }}
        />
        <NotificatiesMenu />
        <AdviseurMenu vestigingNaam={vestigingNaam} organisatieNaam={organisatieNaam} />
      </NavigationBar>
      {/* istanbul ignore next */ showMeldingmaken && user && (
        <SupportTicketModalAjax
          adviesdossierId={params.adviesdossier ?? null}
          user={user}
          closeModal={() => setShowMeldingMaken(false)}
          showInvalidResultErrorPage={() => setShowInvalidAdviesboxResultErrorPage(true)}
          vestigingId={params.vestiging}
        />
      )}
      {/* istanbul ignore next */ showRemoteAssistance && (
        <SupportRemoteAssistanceModal closeModal={() => setShowRemoteAssistance(false)} />
      )}
    </nav>
  );
};

TopNavigation.displayName = "TopNavigation";

export default TopNavigation;
